<template>
  <div>
    <div class="content content-fixed bd-b">
        <div :class="`container pd-x-0 pd-lg-x-10 pd-xl-x-0 ${getScreenWidth()<=992?'table-responsive':''}`">
            <div class="d-sm-flex align-items-center justify-content-between">
              <div>
                  <h4 class="mg-b-5">Дараалал</h4>
                  <p class="mg-b-0 tx-color-03">Нийт:  {{listRaw.length}} бичилт</p>
              </div>
              <div class="mg-t-20 mg-sm-t-0">
                <div class="d-flex">
                  <select v-model="statusCur" class="form-control mg-r-5" placeholder="Төлөв">
                    <option class="tx-color-03" :value="null">-- Сонгоно уу --</option>
                    <option v-for="(s,i) in listStatuses" :key="i" :value="s.statusId">{{s.name}}</option>
                  </select>
                  <input v-model="plateNo" class="form-control" placeholder="Улсын дугаар" />
                  <div class="btn-group mg-l-5">  
                    <button @click="search()" class="btn btn-primary">Хайх</button>
                    <button @click="statusCur=null; plateNo=''; init(true)" class="btn btn-light">Бүгд</button>
                    <button @click="printRangeSetting()" class="btn btn-success btn-uppercase">Хэвлэх</button>
                  </div>
                </div>
              </div>
              <div class="mg-t-20 mg-sm-t-0">
                  <router-link :to="{name: 'request'}" role="button" class="btn btn-warning"> Шинэ хүсэлт илгээх</router-link>
              </div>
            </div>
        </div>
      </div>

      <div class="content tx-13">
        <div id="mainTbl" class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <table  :class="`table table-striped table-bordered table-hover wd-100p ${getScreenWidth()<=1024?'table-responsive':''}`" border="1" style="border-collapse: collapse">
            <thead>
              <tr>
                <th scope="col">Дарааллын дугаар</th>
                <th scope="col">Машины дугаар</th>
                <th scope="col">Төлөв</th>
                <th scope="col">Хүсэлт илгээсэн огноо</th>
                <th scope="col" class="wd-100 no-print"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(l,i) in list" :key="i" role="button">
                <td>{{l.ord}}</td>
                <td>{{l.plateNo}}</td>
                <td>{{l.status}}</td>
                <!-- <td>{{l.createdDate | moment('YYYY.MM.DD HH:mm')}}</td> -->
                <!-- <td>{{moment(l.createdDate).format('DD.MM.YYYY [&nbsp;] HH:mm')}}</td> -->
                <td>{{l.createdDate}}</td>
                <td class="no-print"><button v-if="l.statusCode!='declined'" @click="listDetailR(l.id)" class="btn btn-primary no-print">Дэлгэрэнгүй</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    <div v-if="dtl.modal" class="modal fade show scrollable" style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content tx-14">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">{{dtl.obj.plateNo}}</h6>
            <button @click="listDetailClose()" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body" style="overflow: auto; height: 70vh">
            <!-- <div class="alert alert-warning mg-b-20" role="alert">
              <p class="mg-b-0-f" v-html="`Танй урд: <b>${dtl.obj.ord}</b>-н машин <br /> 
              Таны хил гарах магадлалтай огноо: <b>${dtl.obj.chanceDate}</b> `"></p>
            </div> -->
            <div class="alert alert-danger mg-b-20 mg-x-15" role="alert" v-if="$route.params.p1!='0'">
              <h3 class="alert-heading">Нууц код: {{$route.params.p1}}</h3>
              <p class="mg-b-0-f">Та дээрхи нууц кодыг тэмдэглэж авна уу. Энэхүү нууц кодыг ашиглан дарааллаа цуцлах хүсэлт илгээх тул нууцлалыг чандлан хадгална уу</p>
            </div>
            
            <div class="row mg-b-20">
              <div class="col-sm-6 col-lg-3 mg-b-10">
                <div class="card card-body bg-warning">
                  <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Таны өмнө</h6>
                  <div class="d-flex d-lg-block d-xl-flex align-items-end">
                    <h2 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{dtl.obj.queueCount}}</h2>
                    <p class="tx-11 text-white mg-b-0">машин</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 mg-b-10">
                <div class="card card-body">
                  <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Таны дараалал</h6>
                  <div class="d-flex d-lg-block d-xl-flex align-items-end">
                    <h2 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{dtl.obj.ord}}</h2>
                    <!-- <p class="tx-11 tx-color-03 mg-b-0">машин</p> -->
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 mg-b-10">
                <div class="card card-body">
                  <!-- <div class="marker marker-ribbon marker-warning marker-top-right pos-absolute t-10 r-0">Магадлалтай</div> -->
                  <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Хил гарах магадлалтай</h6>
                  <div class="d-flex d-lg-block d-xl-flex align-items-end">
                    <h5 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{dtl.obj.chanceDate}}</h5>
                    <!-- <p class="tx-11 tx-color-03 mg-b-0">хил гарах</p> -->
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 mg-b-10">
                <div class="card card-body">
                  <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Төлөв</h6>
                  <div class="d-flex d-lg-block d-xl-flex align-items-end">
                    <h5 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{dtl.obj.status}}</h5>
                    <!-- <p class="tx-11 tx-color-03 mg-b-0">хил гарах</p> -->
                  </div>
                </div>
              </div>

              <!-- <div class="pd-x-10">
              Төлөв: <span :style="`color: ${dtl.obj.statusValue}`">{{dtl.obj.status}}</span>
            </div> -->
            </div>
            
            <iframe :src="dtl.obj.qrFile" style="border: 0; width: 100%; height: 1100px"></iframe>
            <div class="alert alert-warning mg-b-20 mg-x-15" role="alert">
              <h6 class="alert-heading">Санамж!</h6>
              <p class="mg-b-0-f">Дээрх тусгай зөвшөөрлийг хуурамчаар үйлдсэн, хуурамч болохыг мэдсээр байж ашигласан,
              худалдсан бол Монгол Улсын Эрүүгийн хуулийн тусгай ангийн 23.2 дугаар зүйлд заасан "Хуурамч баримт
              бичиг үйлдэх, ашиглах" гэмт хэрэг үйлдсэнд тооцож, эрүүгийн хариуцлага хүлээлгэнэ.</p>
            </div>
          </div>
          <div class="">
            
            <div class="d-flex pd-10 align-items-center">
              <button @click="declineReq(1)" class="btn btn-xs btn-danger btn-uppercase">Цуцлах хүсэлт</button>
              <div class="mg-l-auto btn-group">
                <button @click="printReq()" class="btn btn-primary btn-uppercase">Хэвлэх</button>
                <button @click="declineReq(0)" class="btn btn-warning btn-uppercase">Цуцлах</button>
                <button @click="declineReq(100)" class="btn btn-info btn-uppercase">Мэдээлэл шинэчлэх</button>
              </div>
            </div>
            <div class="tx-color-03 tx-11 pd-x-10 mg-b-10">Та өөрийн дарааллаа цуцлах бол ЦУЦЛАХ товчийг дарж нууц кодоо оруулан цуцлана уу</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="decl.modal" class="modal fade show" style="display: block; padding-right: 17px;">
      <div class="modal-dialog" role="document">
        <div class="modal-content tx-14">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">{{dtl.obj.plateNo}}</h6>
            <button @click="listDetailClose()" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="decl.mode==0" class="form-group">
                <label>Нууц код</label>
                <input v-model="decl.pass" type="text" class="form-control">
             </div>
             <div v-else-if="decl.mode==1" class="form-group">
                <label>Хүсэлт</label>
                <textarea v-model="decl.req" type="text" class="form-control" rows="2"></textarea>
             </div>
             <div v-else-if="decl.mode==100">
                <div class="form-group">
                  <label>Нууц код</label>
                  <input v-model="decl.pass" type="text" class="form-control" />

                  <label>Овог</label>
                  <input v-model="decl.lastName" type="text" class="form-control" />
                  <label>Нэр</label>
                  <input v-model="decl.firstName" type="text" class="form-control" />
                  <label>Регистр №</label>
                  <input v-model="decl.regNo" type="text" class="form-control" />
                  <label>Утас</label>
                  <input v-model="decl.mobile" type="text" class="form-control" />
                  <label>Хаяг</label>
                  <textarea v-model="decl.address" type="text" class="form-control" rows="2"></textarea>
                  <label>Вакцины гэрчилгээ</label>
                  <simple-uploader @on-success="thirdSuccess" class="wd-100p">
                    <template v-slot:trigger>
                        <div v-if="decl.thirdAttachmentId==null" class="btn btn-outline-warning btn-block">сонгох</div>
                        <div @click.stop="decl.thirdAttachmentId=null" v-else class="btn btn-outline-danger btn-block">файл устгах</div>
                    </template>
                  </simple-uploader>
                  <label>Жолооны үнэмлэх</label>
                  <simple-uploader @on-success="defaultSuccess" class="wd-100p">
                    <template v-slot:trigger>
                        <div v-if="decl.defaultAttachmentId==null" class="btn btn-outline-warning btn-block">сонгох</div>
                        <div @click.stop="decl.defaultAttachmentId=null" v-else class="btn btn-outline-danger btn-block">файл устгах</div>
                    </template>
                  </simple-uploader>
              </div>
             </div>
          </div>
          <div class="modal-footer">
            <button v-if="decl.mode==100" @click="declineDo" class="btn btn-warning">Шинэчлэх</button>
            <button v-else @click="declineDo" class="btn btn-warning">Цуцлах</button>            
          </div>
        </div>
      </div>
    </div>

    <div v-if="print.modal" class="modal fade show" style="display: block; padding-right: 17px;">
      <div class="modal-dialog" role="document">
        <div class="modal-content tx-14">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">Хэвлэх</h6>
            <button @click="listDetailClose()" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
                <label>Дараалал эхлэх</label>
                <input v-model="print.min" type="text" class="form-control">
             </div>
             <div class="form-group">
                <label>Дараалал хүртэл</label>
                <input v-model="print.max" type="text" class="form-control">
             </div>
          </div>
          <div class="modal-footer">
            <button @click="printRange()" class="btn btn-success">Хэвлэх</button>            
          </div>
        </div>
      </div>
    </div>
      
    <div v-if="dtl.modal" @click="dtl.modal=false" class="modal-backdrop fade show"></div>
    <div v-if="decl.modal" @click="decl.modal=false" class="modal-backdrop fade show"></div>
    <div v-if="print.modal" @click="print.modal=false" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { request } from '@/api/all';
import { mapActions } from "vuex";

import SimpleUploader from "@/components/simpleUploader.vue";

export default {
  components:{
    SimpleUploader
  },
  data(){
    return{
      list: [],
      listStatuses: [],
      dtl:{
        modal: false,
        obj:{}
      },
      decl:{
        modal: false,
        mode: 0,
        pass: '',
        req: '',

        lastName: '',
        firstName: '',
        regNo: '',
        mobile: '',
        address: '',
        address: '',

        defaultAttachmentId: null,
        thirdAttachmentId: null,
      },
      listRaw: [],
      plateNo: '',
      statusCur: null,
      print:{
        modal: false,
        min: null,
        max: null
      },
      hideCode: false,
    }
  },
  mounted(){
    this.init();
  },
  watch: {
    "$root.$route"(newRoute, oldRoute) {
      if(newRoute.params.id>0){
        this.listDetail(newRoute.params.id);
      }
    },
  },
  methods:{
    ...mapActions(["handleLoading"]),
    search(){
      let _rv= this.listRaw;
      _rv= _rv.filter(x=> x.plateNo.indexOf(this.plateNo.toUpperCase()) !== -1 );
      if(this.statusCur!=null){
        _rv= _rv.filter(x=> x.statusId==this.statusCur );
      }
      this.list= _rv;
    },
    init(force){
      this.list=[];
      this.handleLoading({ isLoading: true });
      request({
          api: { method: 'GET', url: 'Tads/SequenceList'},
          //data: this.postModel,
          store: this.$store,
          }).then(res => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            this.listStatuses= res.data.types;
            //console.log(res.data.types);
            this.list=res.data.tasks;
            this.listRaw=res.data.tasks;
            if(parseInt(this.$route.params.id, 10)>0 && force==undefined){
              this.listDetail(parseInt(this.$route.params.id, 10));
            }
          }
      });
    },
    listDetailClose(){
      this.dtl.modal=false;
      this.decl.modal=false;
      this.print.modal=false;

      this.$router.push({name: 'list', params: {id: 0, p1: '0'}})
    },
    listDetailR(id){
      this.$router.push({name: 'list', params: {id: id, p1: '0'}})
    },
    listDetail(id){
      this.handleLoading({ isLoading: true });
      request({
          api: { method: 'GET', url: 'Tads/SequenceDetail '},
          params: {id: id},
          store: this.$store,
          }).then(res => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            //console.log(res);
            this.dtl.obj= res.data.detail;
            this.dtl.modal= true;
          }
      });
    },
    printReq(){
      window.open(this.dtl.obj.qrFile + "?i=" + Math.floor(Math.random() * 100), "_blank" );
    },
    declineReq(m){
      this.dtl.modal= false;
      this.decl.mode= m;
      this.decl.modal= true;
    },
    declineDo(){
      this.handleLoading({ isLoading: true });
      if(this.decl.mode==0){
        request({
          api: { method: 'POST', url: 'Tads/DeclineTadsSequence'},
          data: {plateNo: this.dtl.obj.plateNo, pass: this.decl.pass},
          store: this.$store,
          }).then(res => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            this.init();
            this.decl.modal= false;
          }
        });
      } else if(this.decl.mode==1){
        request({
          api: { method: 'POST', url: 'Tads/CreateTadsDeclineRequest'},
          data: {plateNo: this.dtl.obj.plateNo, desc: this.decl.req, id: this.dtl.obj.id},
          store: this.$store,
          }).then(res => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            this.init();
            this.decl.modal= false;
          }
        });
      } else if(this.decl.mode==100){
        request({
          api: { method: 'POST', url: 'tads/UpdateTadsSequence'},
          data: {
            id: this.dtl.obj.id, 
            pass: this.decl.pass,
            lastName: this.decl.lastName,
            firstName: this.decl.firstName,
            regNo: this.decl.regNo, 
            mobile: this.decl.mobile, 
            address: this.decl.address, 
            thirdAttachmentId : this.decl.thirdAttachmentId, 
            defaultAttachmentId : this.decl.defaultAttachmentId, 
          },
          store: this.$store,
          }).then(res => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            alert('Амжилттай');
            this.init();
            this.decl.modal= false;
          }
        });
      }
    },
    printRangeSetting(){
      this.print.modal= true;
    },
    printRange() {
      this.list= this.listRaw.filter(x=>x.ord>=this.print.min && x.ord<=this.print.max);
      this.print.modal=false;

      this.$nextTick(()=>{
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + document.title  + '</title><style>@media print{.no-print, .no-print * { display: none !important; } }</style>');
        mywindow.document.write('</head><body >');
        mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById('mainTbl').innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
      })      
    },
    getScreenWidth(){
      return screen.width;
    },

    defaultSuccess(e) {
      this.decl.defaultAttachmentId= e.uploadedAttachmentId
    },
    thirdSuccess(e) {
        this.decl.thirdAttachmentId= e.uploadedAttachmentId
    },


  }
}
</script>

<style>
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>